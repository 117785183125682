
import slide1 from '../images/homepagePics/fourthFundayFlyer.png';
import slide2 from '../images/events/sponsorshipForm.png';
import slide3 from '../images/homepagePics/pressReleaseMarch23.JPG';


export const Content = [

    {
        image: slide1,
        info: '',
        height: 650,
        width: 550,
        link: '../events',
    },
    {
        image: slide2,
        info: 'information',
        height: 650,
        width: 550,
        // link: '../events',
    },
    {
        image: slide3,
        info: '',
        height: 650,
        width: 550,
        // link: '../programs',
    }
]