import cloud from '../images/emojis/cloudEmoji.JPG';
import rainbow from '../images/emojis/rainbowEmoji.jpg';
import storm from '../images/emojis/stormyEmoji.JPG';
import sun from '../images/emojis/sunEmoji.JPG';
import tornado from '../images/emojis/tornadoEmoji.jpg';

export const Emojis=[
    {
        image: cloud,
        height: 500,
        width: 300
    },
    {
        image: rainbow,
        height: 500,
        width: 300
    },
    {
        image: storm,
        height: 500,
        width: 300
    },
    {
        image: sun,
        height: 500,
        width: 300
    },
    {
        image: tornado,
        height: 500,
        width: 300
    }
]