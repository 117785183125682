import './css/App.scss';
import React from 'react';
import Events from './routes/events.js';
import AboutUs from './routes/aboutUs.js';
import Donate from './routes/donate.js';
import Resources from './routes/resources.js';
import Shop from './routes/shop.js';
import Programs from './routes/programs.js';
import Home from './routes/homepage.js';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import TopNav from './components/navBar.js';
import Scroll from './components/ScrollToTop.js';


export default class App extends React.Component {
  render() {
    return (
      <div id="positive-somebody" className="app">
        <BrowserRouter>
          <Scroll />
          <TopNav />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="events" element={<Events />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="donate" element={<Donate />} />
            <Route path="resources" element={<Resources />} />
            <Route path="shop" element={<Shop />} />
            <Route path="programs" element={<Programs />} />
            <Route path="home" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}


