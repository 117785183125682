import React from 'react';
import '../css/aboutUs.scss';
import { BoardInfo } from '../configs/boardInfo';
import sun from '../images/plainEmojis/sunPlain.png';
import cloud from '../images/plainEmojis/cloudPlain.png';
import rainbow from '../images/plainEmojis/rainbowPlain.png';
import storm from '../images/plainEmojis/stormPlain.png';
import tornado from '../images/plainEmojis/tornadoPlain.png';
export default function AboutUs() {

    return (
        <div>
            <section id="header" className="header">
                <div className="aboutUs-header">
                    <h2 className="emojis">
                        <img className="emoji" src={sun} height="100px" width="150px" />
                        <img className="emoji" src={cloud} height="100px" width="150px" />
                        <img className="emoji" src={rainbow} height="100px" width="150px" />
                        <img className="emoji" src={storm} height="100px" width="150px" />
                        <img className="emoji" src={tornado} height="100px" width="150px" />
                    </h2>
                    <h3 className="our-mission">Our Mission</h3>
                    <p className="mission">Positive Somebody is a nonprofit organization  that aims to spread awareness on the importance of positive coping skills by providing children tools through teachings and modeling of different positive coping mechanisms.  Positive Somebody is dedicated to helping both public and private organizations. By giving individuals the positive mental health tools to handle whatever life brings, we hope to help them achieve a positive life.</p>
                </div>
            </section>
            {/* <div className='divider'></div> */}
            <div className="meet-our-team">
                <div className="divider">
                    <h2 className="meet">
                        Meet Our Team
                    </h2>
                </div>
                <div className="boardMembers">
                    {BoardInfo.map((item = {}, index) => {
                        return (
                            <div key={index} className="column">
                                <div className="card">
                                    <div className="image-information">
                                        <div className="headshot-container">
                                            <img className="headshot" src={item.image} alt="Headshot" />
                                        </div>
                                        <div className="name-position">
                                            <p className="name">{item.name}</p>
                                            <p className="position">{item.position}</p>
                                            <p className="employment">{item.currentEmployment}</p>
                                        </div>
                                    </div>

                                    <p className="bio">{item.info}</p>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    );
}
