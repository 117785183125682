import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const NavItem = (props) => {
    
    const [showSubLinks, setShowSubLinks] = useState(false);
    var value = showSubLinks && !!props.item.subLinks;

    const handleClick = () => {
        setShowSubLinks(!showSubLinks);
        if (props.closeMobileMenu) {
          props.closeMobileMenu(); // Call the function to close the mobile navigation
        }
      };
    return(
        
        <li
        className="navItem"
        onMouseEnter={() => setShowSubLinks(true)}
        onMouseLeave={() => setShowSubLinks(false)}
        onClick={handleClick}
      >
            {props.item.external ? 
            <a target="_blank" rel="noreferrer" href={props.item.link} className={props.item.iName}>{props.item.title}</a>
            :
            <Link
            to={props.item.link} className={props.item.iName} >
                {props.item.title}
            </Link>}
            {(value) && 
                <div className="subLinkDrop" >      
                    {props.item.subLinks.map((subLinkItem, subLinkIndex) =>{
                        return(
                            <a key={subLinkIndex} className="subLink">
                                {subLinkItem.title}
                            </a>
                        )
                    })}                                    
                </div>
            }
        </li>
    );
}
NavItem.propTypes={
    item: PropTypes.object,
    closeMobileMenu: PropTypes.func,
}
export default NavItem;
