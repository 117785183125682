import React from 'react';
import '../css/programs.scss';
import table from '../images/events/eventTable.jpg';

import { Packs } from '../configs/packs.js';

import { Emojis } from '../configs/emojis';
import Carousel from '../components/carousel';
export default function Programs() {
    return (
        <body className='programs-container'>

            <div className="pack-container center-text">

                <div className="pack-content-row">
                    <Carousel className="carousel-programs" imageArray={Packs} />
                    <div className='text-container'>
                        <h1>Positive Packs</h1>
                        <p>We bring positivity to schools and organizations with our thoughtfully curated Positive Packs. Each pack is designed to inspire and support. Packs include:</p>
                        <ul>
                            <li>Customized Journals: Personalized spaces for reflection and creativity.</li>
                            <li>Colorful Pencils: Tools for both learning and artistic expression.</li>
                            <li>Stress Balls & Breathe Stones: Items to help manage stress and encourage mindfulness.</li>
                            <li>Positive Affirmation Wristbands: Wearable reminders of encouragement and self-belief.</li>
                            <li>Fidgets: Fun and engaging tools to promote focus and relaxation.</li>
                            <li>Positive Coping Worksheets: Practical resources to develop healthy coping strategies.</li>
                        </ul>
                    </div>
                </div>



            </div>
            <div className="pack-container list">
                <h1>High School chapters</h1>
                <div className="pack-content-row">
                    <div className='text-container'>
                        <p>Our High School Chapters operate like vibrant school clubs, offering a range of enriching activities and opportunities, including: </p>
                        <ul>
                            <li>Positive Coping Activities: Engage in activities designed to build resilience and promote well-being.</li>
                            <li>Educational Lessons: Participate in informative sessions that foster personal growth and mental health awareness.</li>
                            <li>Community Outreach Opportunities: Extend your impact by partnering with middle and elementary schools to support younger students.</li>
                            <li>Promotion of Positive Mental Health: Advocate for and contribute to initiatives that encourage mental wellness and positivity.</li>
                        </ul>
                        <br />
                        <p>For more information, contact us at beapositivesomebody@gmail.com</p>
                    </div>
                    <img src={table} className="image" />
                </div>
            </div>


            <div className="pack-container grant">
                <h1>Roberson-Bell Grant</h1>
                <p>This grant aims to provide positive outlets, such as music, art, sports, and other activities to youth that are unable to afford it otherwise.</p>
            </div>
            <div className="emoji-container">
                <h1 className="emoji-header">Meet our emojis</h1>
                <Carousel className="carousel-programs" imageArray={Emojis} />
            </div>
        </body>

    );
}