import React from 'react';
import { ResourceInfo } from '../configs/resourceInfo';
import '../css/resources.scss';
export default function Resources() {
    return(
        <div className="allContent">
        <div className="pageContent">
            <div className="info"><h3 className="alert">If the situation is potentially life-threatening, get immediate emergency assistance by calling <a href="tel:911">911</a>, available 24 hours a day.</h3></div>
            <div className="info">
                <h3>Suicide &amp; Crisis Lifeline</h3>
                <p>There is hope. Providing 24/7, free and confidential support to people in suicidal crisis or emotional distress works. The Lifeline helps thousands of people overcome crisis situations every day.</p>
                <a href="tel:988">988</a>
            </div>
            <div className="info">
                <h3>National Suicide Prevention Lifeline</h3>
                <p>The Lifeline provides 24/7, free and confidential support for people in distress, prevention and crisis resources for you or your loved ones, and best practices for professionals in the United States.</p>
                <a href="tel:1-800-273-8255">1-800-273-8255</a>
                <a href="https://suicidepreventionlifeline.org/">https://suicidepreventionlifeline.org/</a>
            </div>
            <div className="info">
                <h3>OK2TALK</h3>
                <p>OK2TALK is a community where teens and young adults struggling with mental health conditions can find a safe place to talk about what they’re experiencing by sharing their personal stories of recovery, tragedy, struggle or hope. Anyone can add their voice by sharing stories, poems, inspirational quotes, photos, videos, song lyrics and messages of support in a safe, moderated space. We hope this is the first step towards helping you get the support you need to feel better.</p>
                    <a href="https://ok2talk.org/">https://ok2talk.org/</a>
                </div>
            <div className="additionalResources">
                <h2>Additional Resources</h2>
                <table>
                <tr>
                    <th>Resource</th>
                    <th>Phone number</th>
                </tr>         
                    {ResourceInfo.map((item = {}, index)=>{
                        return(
                            <tr key={index} className="table-row">
                            <td className="resource">{item.name}</td> 
                            <td className="phoneNumber"><a href={`tel:${item.phone}`}>{item.phone}</a></td>
                            </tr>   
                        )
                    })}
                </table>
            </div>
        </div>
        </div>
    );
    }