export const NavItems = [
    {
        title: 'About Us',
        link: '/aboutUs',
        iName: 'nav-links'

    },

    {
        title: 'Programs',
        link: '/programs',
        iName: 'nav-links',
    },
    {
        title: 'Events',
        link: '/events',
        iName: 'nav-links'

    },
    {
        title: 'Resources',
        link: '/resources',
        iName: 'nav-links',
    },
    {
        title: 'Shop',
        link:  'https://stores.inksoft.com/positive_somebody/shop/home',
        iName: 'nav-links',
        external: true
    },
]