import pack1 from '../images/positivePacks/pack1.jpg';
import pack2 from '../images/positivePacks/pack2.jpg';
import pack3 from '../images/positivePacks/pack3.jpg';



export const Packs=[
    {
        image: pack1,
        height: 500,
        width: 300
    },
    {
        image: pack2,
        height: 500,
        width: 300
    },
    {
        image: pack3,
        height: 500,
        width: 300
    },
]